var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Pre-Repair Inspections")]),_c('v-card-text',[_c('v-fade-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionAlert),expression:"actionAlert"}],attrs:{"border":"left","color":"success","dark":"","text":"","dismissible":""}},[_vm._v(" "+_vm._s(_vm.actionAlertMessage)+" ")])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.$store.getters['auth/can'](['create pre-repairs']))?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Pre-Repair")])],1):_vm._e()],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.preRepairs,"options":_vm.options,"server-items-length":_vm.totalPreRepairs,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{staticClass:"font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'pre-repairs-edit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.plate_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.vehicle.plate_number)+" ")])]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{staticClass:"font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'vehicles-view', params: { id: item.vehicle.id } }}},[_vm._v(" "+_vm._s(item.vehicle.make.name)+" "+_vm._s(item.vehicle.make_model.name)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.vehicle.vehicle_type ? item.vehicle.vehicle_type.type : ''))])],1)]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.resolveDate(item.start_date)))])]}},( _vm.$store.getters['auth/can'](['create job orders']))?{key:"item.actions_2",fn:function(ref){
var item = ref.item;
return [(!item.job_order)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","small":"","width":"150"},on:{"click":function($event){return _vm.createJobOrder(item)}}},[_vm._v(" Create Job Order ")]):_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","small":"","width":"150","to":{ name: 'job-orders-edit', params: { id: item.job_order.id } }}},[_vm._v(" View Job Order ")])]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(_vm.$store.getters['auth/can'](['edit pre-repairs']))?_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1):_vm._e(),(_vm.$store.getters['auth/can'](['delete pre-repairs']))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteItemConfirm(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialogDelete = !_vm.dialogDelete}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItem}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }