<template>
  <v-card>
    <v-card-title>Pre-Repair Inspections</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="$store.getters['auth/can'](['create pre-repairs'])"
          color="primary"
          class="mb-4 me-3"
          @click="create"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Pre-Repair</span>
        </v-btn>

        <!-- <v-btn
          color="secondary"
          outlined
          disabled
          class="mb-4"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="preRepairs"
        :options.sync="options"
        :server-items-length="totalPreRepairs"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- id -->
        <template #[`item.id`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'pre-repairs-edit', params: { id: item.id } }"
            >
              {{ item.id }}
            </router-link>
          </div>
        </template>

        <!-- plate_number -->
        <template #[`item.plate_number`]="{ item }">
          <div class="d-flex flex-column">
            {{ item.vehicle.plate_number }}
          </div>
        </template>

        <!-- vehicle -->
        <template #[`item.vehicle`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'vehicles-view', params: { id: item.vehicle.id } }"
            >
              {{ item.vehicle.make.name }} {{ item.vehicle.make_model.name }}
            </router-link>
            <span class="text-xs">{{ item.vehicle.vehicle_type ? item.vehicle.vehicle_type.type : '' }}</span>
          </div>
        </template>

        <!-- start_date  -->
        <template #[`item.start_date`]="{item}">
          <span>{{ resolveDate(item.start_date) }}</span>
        </template>

        <!-- actions 2 -->
        <template
          v-if=" $store.getters['auth/can'](['create job orders'])"
          #[`item.actions_2`]="{item}"
        >
          <v-btn
            v-if="!item.job_order"
            class="mr-2"
            color="secondary"
            small
            width="150"
            @click="createJobOrder(item)"
          >
            Create Job Order
          </v-btn>
          <v-btn
            v-else
            class="mr-2"
            color="success"
            small
            width="150"
            :to="{ name: 'job-orders-edit', params: { id: item.job_order.id } }"
          >
            View Job Order
          </v-btn>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$store.getters['auth/can'](['edit pre-repairs'])"
                @click="editItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item :to="{ name: 'pre-repairs-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item
                v-if="$store.getters['auth/can'](['delete pre-repairs'])"
                @click="deleteItemConfirm(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialogDelete = !dialogDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItem"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import usePreRepairs from './usePreRepairs'
import router from '@/router'
import useUIResolver from '../useUIResolver'

const defaultAlertMessage = 'PreRepair successfully saved.'

export default {
  setup() {
    const preRepairData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const searchQuery = ref('')
    const totalPreRepairs = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const { preRepairs, getPreRepairs } = usePreRepairs()
    const { preRepairTableHeaders, resolveDate } = useUIResolver()

    const fetchPreRepairs = () => {
      const params = { ...options.value, ...{ searchQuery: searchQuery.value } }
      if (router.currentRoute.name === 'vehicles-view') {
        params.vehicle_id = router.currentRoute.params.id
      }
      getPreRepairs(params).then(response => {
        totalPreRepairs.value = response.data.total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    const createJobOrder = item => {
      router.push({ name: 'job-orders-create', query: { vehicle_id: item.vehicle.id, pre_repair_id: item.id } })
    }

    onMounted(() => {
      actionAlertMessage.value = defaultAlertMessage
      headers.value = preRepairTableHeaders()
      if (router.currentRoute.name === 'vehicles-view') {
        // unset VEHICLE header
        headers.value = preRepairTableHeaders().filter(header => !['vehicle', 'plate_number'].includes(header.value))
      }
    })

    const clearSearch = () => {
      searchQuery.value = null
      fetchPreRepairs()
    }

    const create = () => {
      if (router.currentRoute.name === 'vehicles-view') {
        router.push({ name: 'pre-repairs-create', query: { vehicle_id: router.currentRoute.params.id } })
      } else {
        router.push({ name: 'pre-repairs-create' })
      }
    }

    const savePreRepair = () => {
      actionAlertMessage.value = defaultAlertMessage
      actionAlert.value = true
      fetchPreRepairs()
    }

    const editItem = item => {
      router.push({ name: 'pre-repairs-edit', params: { id: item.id } })
    }

    const deleteItem = () => {
      axios.delete(`/pre-repairs/${preRepairData.value.id}`).then(response => {
        actionAlertMessage.value = 'PreRepair successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchPreRepairs()
      })
    }

    const deleteItemConfirm = item => {
      preRepairData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchPreRepairs)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      preRepairData,
      headers,
      searchQuery,
      totalPreRepairs,
      footerProps,
      options,
      resolveDate,
      createJobOrder,

      // usPreRepairs
      preRepairs,
      getPreRepairs,

      // functions
      clearSearch,
      create,
      savePreRepair,
      fetchPreRepairs,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
      },
    }
  },
}
</script>
