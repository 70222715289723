import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function usePreRepairs() {
  const preRepairs = ref([])
  const preRepair = ref({})
  const loading = ref(false)
  const searchPreRepair = ref(null)

  const getPreRepairs = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/pre-repairs/filter', params)
        .then(response => {
          const { data } = response.data
          preRepairs.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getPreRepair = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/pre-repairs/${id}`)
        .then(response => {
          const { data } = response
          preRepair.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchPreRepair,
    preRepairs,
    preRepair,

    // functions
    getPreRepairs,
    getPreRepair,
  }
}
